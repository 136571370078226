body {
  font-family: Roboto, Helvetica, Arial, sans-serif;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*{
  padding:0;
  margin:0;
}

.bucket{
  height:100vh;
  background-color: white;
  position:relative;
  display: flex;
  flex-direction: row;
}

.bucket .sidebar{
  position:fixed;
  background-color:#fff;
  height:100vh;
  width:90px;
  z-index:10;
  text-align:center;
  box-shadow:0px 15px 30px rgba(0,0,0,0.1);
  float: left;
}




.bucket .content{
  
  padding-left:75px;
  background-color: white;
  margin-top: 50px;
}
